import {mapActions, mapMutations} from 'vuex';
export default {
    methods:{
        confirmLogout(){
          this.logout().then(()=>{
              this.changeToggleExitConfirm(false);
              document.location.reload();
          });
        },
        ...mapActions({
            logout:'auth/logout'
        }),
        ...mapMutations({
            changeToggleExitConfirm:'config/changeToggleExitConfirm',
        })
    }
}